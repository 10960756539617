import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import { margins } from 'constants/spacing';

import Icon1 from './images/icon-01.inline.svg';
import Icon2 from './images/icon-02.inline.svg';
import Icon3 from './images/icon-03.inline.svg';
import Icon4 from './images/icon-04.inline.svg';
import Icon5 from './images/icon-05.inline.svg';
import Icon6 from './images/icon-06.inline.svg';
import Icon7 from './images/icon-07.inline.svg';
import Icon8 from './images/icon-08.inline.svg';
import Icon9 from './images/icon-09.inline.svg';
import Icon10 from './images/icon-10.inline.svg';
import Icon11 from './images/icon-11.inline.svg';
import Icon12 from './images/icon-12.inline.svg';

const ANIMATION_EASE = [0.25, 0.1, 0, 1];
const ANIMATION_DURATION = 2;

const arrowsVariants = {
  from: {
    opacity: 0,
    scale: 1.4,
  },
  to: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: ANIMATION_EASE,
      duration: ANIMATION_DURATION,
    },
  },
};

const iconsVariants = {
  from: {
    ...arrowsVariants.from,
    rotate: -25,
  },
  to: {
    ...arrowsVariants.to,
    rotate: 0,
  },
};

const titleVariants = {
  from: {
    opacity: 0,
    translateY: 60,
  },
  to: {
    opacity: 1,
    translateY: 0,
    transition: {
      ease: ANIMATION_EASE,
      duration: ANIMATION_DURATION,
    },
  },
};

const buttonVariants = {
  from: {
    ...titleVariants.from,
    translateY: 100,
  },
  to: titleVariants.to,
};

const CTA = ({ title, buttonText, buttonUrl, marginTop }) => {
  const [animationVisibilityRef, isInView] = useInView({
    threshold: 0.6,
    triggerOnce: true,
  });

  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start('to');
    }
  }, [controls, isInView]);

  return (
    <motion.section
      className={classNames(
        'text-center border-b border-primary-white py-40 bg-primary-black overflow-hidden xl:py-20',
        margins.top[marginTop]
      )}
      initial="from"
      animate={controls}
      ref={animationVisibilityRef}
    >
      <Container>
        <div className="relative z-10">
          <motion.div variants={titleVariants}>
            <Heading
              tag="h2"
              size="lg"
              theme="primary-white"
              className="max-w-[540px] mx-auto xl:max-w-[400px]"
            >
              {title}
            </Heading>
          </motion.div>
          <motion.div variants={buttonVariants}>
            <Button to={buttonUrl} className="mt-10 xl:mt-8" size="md" theme="primary-white-filled">
              {buttonText}
            </Button>
          </motion.div>
        </div>
        <motion.div
          className="absolute -left-40 -bottom-24 xl:-left-1 xl:-bottom-7 lg:hidden"
          variants={iconsVariants}
        >
          <Icon1 className="w-[50px] xl:w-[31px]" aria-hidden />
        </motion.div>
        <motion.div
          className="absolute -left-12 bottom-15 xl:left-14 xl:bottom-15 lg:hidden sm:-bottom-6 sm:right-6 sm:left-auto sm:block xs:hidden"
          variants={iconsVariants}
        >
          <Icon2 className="w-[45px] xl:w-[26px]" aria-hidden />
        </motion.div>
        <motion.div
          className="absolute top-[-94px] -left-7 xl:left-[71px] xl:top-[-22px] lg:hidden"
          variants={iconsVariants}
        >
          <Icon3 className="w-[63px] xl:w-[34px]" aria-hidden />
        </motion.div>
        <motion.div
          className="absolute left-[116px] -bottom-16  xl:left-[151px] xl:bottom-[-10px] lg:left-[22px] lg:-bottom-3 md:hidden sm:block sm:bottom-[6px] sm:left-[-5px] sm:w-[43px] xs:hidden"
          variants={iconsVariants}
        >
          <Icon4 className="w-[60px] xl:w-[35px]" aria-hidden />
        </motion.div>
        <motion.div
          className="absolute -top-7 left-[167px] xl:left-44 xl:top-3 lg:left-[54px] lg:top-0 md:top-[13px] md:left-9 sm:hidden"
          variants={iconsVariants}
        >
          <Icon5 className="w-[61px] xl:w-[32px]" aria-hidden />
        </motion.div>
        <motion.div
          className="absolute left-[329px] -bottom-16 xl:left-[267px] xl:-bottom-5 lg:left-[140px] md:left-[60px] md:bottom-[-15px] sm:hidden"
          variants={arrowsVariants}
        >
          <Icon6 className="w-[43px] xl:w-[35px]" aria-hidden />
        </motion.div>
        <motion.div
          className="absolute bottom-[-77px] right-[345px] xl:right-[292px] xl:-bottom-7 lg:right-[148px] lg:-bottom-8 md:-bottom-2 md:right-[78px] sm:hidden"
          variants={iconsVariants}
        >
          <Icon7 className="w-[50px] xl:w-[35px]" aria-hidden />
        </motion.div>
        <motion.div
          className="absolute right-[235px] bottom-[93px] xl:bottom-[75px] xl:right-[224px] lg:top-[34px] lg:right-[87px] md:right-7 md:top-4 sm:-top-14 sm:-right-2 xs:hidden"
          variants={iconsVariants}
        >
          <Icon8 className="w-[61px] xl:w-[37px]" aria-hidden />
        </motion.div>
        <motion.div
          className="absolute top-[-54px] right-[121px] xl:top-[-9px] xl:right-[154px] lg:-top-8 lg:right-[26px] md:hidden"
          variants={arrowsVariants}
        >
          <Icon9 className="w-[42px] xl:w-[26px]" aria-hidden />
        </motion.div>
        <motion.div
          className="absolute bottom-0 right-23 xl:bottom-[18px] xl:right-[136px] lg:right-3 lg:bottom-4 md:hidden"
          variants={iconsVariants}
        >
          <Icon10 className="w-[60px] xl:w-[36px]" aria-hidden />
        </motion.div>
        <motion.div
          className="absolute right-[-94px] top-[-78px] xl:-top-6 xl:right-[22px] lg:hidden"
          variants={iconsVariants}
        >
          <Icon11 className="w-[50px] xl:w-[32px]" aria-hidden />
        </motion.div>
        <motion.div
          className="absolute right-[-132px] -bottom-10 xl:bottom-[-6px] xl:right-[-1px] lg:hidden"
          variants={iconsVariants}
        >
          <Icon12 className="w-[62px] xl:w-[37px]" aria-hidden />
        </motion.div>
      </Container>
    </motion.section>
  );
};

CTA.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  marginTop: PropTypes.oneOf(Object.keys(margins.top)),
};

CTA.defaultProps = {
  marginTop: null,
};

export default CTA;
